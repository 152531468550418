import { HtmlTrans } from '@components/shared/HtmlTrans';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferTasksPageContent: React.FC = () => {
  return (
    <>
      <S.Slide>
        <img src="/images/user-guiding-offer-tasks-page-01.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer_page_tasks_tab.slide1.content"
        />
      </S.Slide>
      <S.Slide>
        <img src="/images/user-guiding-offer-tasks-page-02.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer_page_tasks_tab.slide2.content"
        />
      </S.Slide>
    </>
  );
};
