import { useLoggedUser } from '@hooks/useLoggedUser';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { useCallback, useEffect, useState } from 'react';
import { UseFeatureFlag } from './useFeatureFlag.types';

// Hook.
// -----------------------------------------------------------------------------
export const useFeatureFlag: UseFeatureFlag = (feature) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const flags = useFlags([feature]);
  const flagsmith = useFlagsmith();

  const [isLoading, setIsLoading] = useState(!flagsmith.identity);

  const { user } = useLoggedUser();

  // Functions.
  // ---------------------------------------------------------------------------
  const identify = useCallback(async () => {
    if (flagsmith.initialised && !flagsmith.identity && user && user.email) {
      const { email } = user;
      await flagsmith.identify(email, { email, name: user.name || null });
    }
  }, [flagsmith, user]);

  // Effects.
  // ---------------------------------------------------------------------------
  useEffect(() => {
    identify();
  }, [identify]);

  useEffect(() => {
    if (flagsmith.initialised) {
      setIsLoading(false);
    }
  }, [flagsmith.identity, flagsmith.initialised]);

  // Return
  // ---------------------------------------------------------------------------
  return {
    isEnabled: flags[feature].enabled,
    isLoading,
    getAllTraits: flagsmith.getAllTraits,
    getTrait: flagsmith.getTrait,
    setTrait: flagsmith.setTrait
  };
};
