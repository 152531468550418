import { HtmlTrans } from '@components/shared/HtmlTrans';
import { SLIDER_SETTINGS } from '@components/UserGuiding/UserGuiding.constants';
import Slider from 'react-slick';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferSearchPageContent: React.FC<{
  isDraft: boolean;
}> = ({ isDraft }) => {
  return (
    <>
      {isDraft ? (
        <S.Slide>
          <img src="/images/user-guiding-offer-search-page-01.png" />
          <HtmlTrans
            ns="common"
            i18nKey={`common.user-guiding-modal.offer-page-searched-tab.slide1_DRAFT.content`}
          />
        </S.Slide>
      ) : (
        <Slider {...SLIDER_SETTINGS}>
          <S.Slide>
            <img src="/images/user-guiding-offer-search-page-02.png" />
            <HtmlTrans
              ns="common"
              i18nKey={`common.user-guiding-modal.offer-page-searched-tab.slide1_ACTIVE.content`}
            />
          </S.Slide>

          <S.Slide>
            <img src="/images/user-guiding-offer-search-page-03.png" />
            <HtmlTrans
              ns="common"
              i18nKey={`common.user-guiding-modal.offer-page-searched-tab.slide2_ACTIVE.content`}
            />
          </S.Slide>
        </Slider>
      )}
    </>
  );
};
