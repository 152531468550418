import { UserGuide } from '@models/user-guide';
import { UserGuideRequest, UserGuideResponse } from '@talent-engine/api-common';
import { ApiServiceOptions, getApiUrl } from '@utils/api';
import { httpClient } from '@utils/http';

export const ServiceUserGuide = {
  get: async (opts?: ApiServiceOptions): Promise<UserGuide[]> => {
    const url = getApiUrl('/user/guide', opts?.useAppProxy);
    const res = await httpClient.get<UserGuideResponse>(url, {
      bearer: opts?.token
    });

    return res.showedGuides;
  },

  updateShowedGuides: async (
    showedGuides: UserGuide[]
  ): Promise<UserGuide[]> => {
    const url = getApiUrl('/user/guide');
    const res = await httpClient.put<UserGuideRequest, UserGuideResponse>(url, {
      showedGuides
    });

    return res.showedGuides;
  }
};
