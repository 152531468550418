import { useMutation } from '@asgard/ui-common';
import { HttpError } from '@models/http-error';
import { UserGuide } from '@models/user-guide';
import { ServiceUserGuide } from '@services/user-guide';
import { USE_SHOWED_USER_GUIDES_KEY } from '../useShowedUserGuides/useShowedUserGuides.constants';
import { UseShowedUserGuidesUpdate } from './useShowedUserGuidesUpdate.types';

export const useShowedUserGuidesUpdate: UseShowedUserGuidesUpdate = () => {
  const { trigger: updateShowedUserGuides } = useMutation<
    UserGuide[],
    UserGuide[],
    HttpError
  >(USE_SHOWED_USER_GUIDES_KEY, async (showedGuide) => {
    return ServiceUserGuide.updateShowedGuides(showedGuide);
  });

  return {
    updateShowedUserGuides
  };
};
