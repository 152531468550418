import styled from 'styled-components';

export const UserGudingTrigger = styled.div`
  position: fixed;
  bottom: 3.5rem;
  right: 3.5rem;
  z-index: 9;

  button {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);

    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

export const Slide = styled.div`
  font-size: 1.125rem;

  img {
    border: 1px solid #efefef;
    padding: 0.25rem;
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;
    display: block;
  }
`;

export const HelpTable = styled.table`
  border-top: 1px solid #e0e0e0;
  margin-top: 3rem;

  tr td {
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem;
  }
`;
