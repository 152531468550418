import { HtmlTrans } from '@components/shared/HtmlTrans';
import { SLIDER_SETTINGS } from '@components/UserGuiding/UserGuiding.constants';
import Slider from 'react-slick';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferPagePublicOffer: React.FC = () => {
  return (
    <Slider {...SLIDER_SETTINGS}>
      <S.Slide>
        <img src="/images/user-guiding-public-offer-page-01.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer-page-public-offer-tab.slide1.content"
        />
      </S.Slide>
      <S.Slide>
        <img src="/images/user-guiding-public-offer-page-02.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer-page-public-offer-tab.slide2.content"
        />
      </S.Slide>
    </Slider>
  );
};
