import { HtmlTrans } from '@components/shared/HtmlTrans';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferNotesPageContent: React.FC = () => {
  return (
    <S.Slide>
      <img src="/images/user-guiding-offer-notes-page-01.png" />
      <HtmlTrans
        ns="common"
        i18nKey="common.user-guiding-modal.offer_page_notes_tab.slide1.content"
      />
    </S.Slide>
  );
};
