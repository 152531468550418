import { IconButton, Modal, Tooltip } from '@/../ui-library/dist';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { useShowedUserGuides } from '@hooks/api/useShowedUserGuides';
import { useShowedUserGuidesUpdate } from '@hooks/api/useShowedUserGuidesUpdate';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useI18n } from '@hooks/useI18n';
import { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { UserGuidingCompanyPageContent } from './guides/UserGuidingCompanyPageContent';
import { UserGuidingOfferCandidatesPageContent } from './guides/UserGuidingOfferCandidatesPageContent';
import { UserGuidingOfferListPageContent } from './guides/UserGuidingOfferListPageContent';
import { UserGuidingOfferNotesPageContent } from './guides/UserGuidingOfferNotesPageContent';
import { UserGuidingOfferOfferedPageContent } from './guides/UserGuidingOfferOfferedPageContent';
import { UserGuidingOfferPagePublicOffer } from './guides/UserGuidingOfferPagePublicOffer';
import { UserGuidingOfferSearchPageContent } from './guides/UserGuidingOfferSearchPageContent';
import { UserGuidingOfferTasksPageContent } from './guides/UserGuidingOfferTasksPageContent';
import * as S from './UserGuiding.styled';
import { UserGuidingProps } from './UserGuiding.types';

export const UserGuiding: React.FC<UserGuidingProps> = ({ guide, offer }) => {
  const { t } = useI18n('common');

  const { isEnabled } = useFeatureFlag('te-client-user-guiding');
  const { showedUserGuides } = useShowedUserGuides();
  const { updateShowedUserGuides } = useShowedUserGuidesUpdate();

  const alreadySent = useRef(false);

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (
      showedUserGuides &&
      !showedUserGuides.includes(guide) &&
      !alreadySent.current
    ) {
      setIsOpened(true);
      alreadySent.current = true;

      (async () => {
        await updateShowedUserGuides([...showedUserGuides, guide]);
      })();
    }
  }, [guide, showedUserGuides, updateShowedUserGuides]);

  if (!isEnabled) {
    return null;
  }

  return (
    <div>
      <Modal
        disableOutsideClick
        title={t(`common:common.user-guiding-modal.${guide}.title`)}
        isOpen={isOpened}
        onClose={() => setIsOpened(false)}
      >
        {guide === 'offer_list_page' && <UserGuidingOfferListPageContent />}

        {guide === 'offer_page_tasks_tab' && (
          <UserGuidingOfferTasksPageContent />
        )}

        {guide === 'offer_page_notes_tab' && (
          <UserGuidingOfferNotesPageContent />
        )}

        {guide === 'offer_page_candidates_tab' && (
          <UserGuidingOfferCandidatesPageContent
            isAgency={offer?.pipelineType === 'AGENCY'}
          />
        )}

        {guide === 'offer-page-searched-tab' && (
          <UserGuidingOfferSearchPageContent
            isDraft={offer?.status === 'PENDING'}
          />
        )}

        {guide === 'offer-page-offered-tab' && (
          <UserGuidingOfferOfferedPageContent
            isDraft={offer?.status === 'PENDING'}
          />
        )}

        {guide === 'company-page' && <UserGuidingCompanyPageContent />}

        {guide === 'offer-page-public-offer-tab' && (
          <UserGuidingOfferPagePublicOffer />
        )}
      </Modal>

      <S.UserGudingTrigger>
        <Tooltip content={t('common:common.user-guiding-trigger_tooltip')}>
          <IconButton
            isRound
            icon={faQuestionCircle}
            size="xs"
            aria-label={t('common:common.user-guiding-trigger')}
            schema="brand2"
            variant="hard"
            onClick={() => setIsOpened(true)}
          />
        </Tooltip>
      </S.UserGudingTrigger>
    </div>
  );
};
