import { HtmlTrans } from '@components/shared/HtmlTrans';
import * as S from '../../UserGuiding.styled';

export const UserGuidingCompanyPageContent: React.FC = () => {
  return (
    <S.Slide>
      <img src="/images/user-guiding-company-page-01.png" />
      <HtmlTrans
        ns="common"
        i18nKey="common.user-guiding-modal.company-page.slide1.content"
      />
    </S.Slide>
  );
};
