import { useQuery } from '@asgard/ui-common';
import { HttpError } from '@models/http-error';
import { UserGuide } from '@models/user-guide';
import { ServiceUserGuide } from '@services/user-guide';
import { USE_SHOWED_USER_GUIDES_KEY } from './useShowedUserGuides.constants';
import { UseShowedUserGuides } from './useShowedUserGuides.types';

export const useShowedUserGuides: UseShowedUserGuides = () => {
  const {
    data: showedUserGuides,
    isLoading,
    error
  } = useQuery<UserGuide[], HttpError>(
    `${USE_SHOWED_USER_GUIDES_KEY}`,
    async () => {
      return ServiceUserGuide.get();
    }
  );

  return {
    showedUserGuides,
    isLoading,
    error
  };
};
