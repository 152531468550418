import {
  BadgeSchema,
  BadgeVariant
} from '@asgard/ui-library/src/components/Molecules/Badge/Badge.types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCirclePause,
  faCircleXmark,
  faMagnifyingGlass,
  faPenLine,
  faTrophyStar,
  faUserGroup
} from '@fortawesome/pro-solid-svg-icons';
import { OfferStatusProps } from './OfferStatus.types';

export const STATUS_DICT: Record<
  OfferStatusProps['status'],
  {
    icon: IconDefinition;
    schema: BadgeSchema;
    variant: BadgeVariant;
  }
> = {
  ACTIVE: {
    icon: faUserGroup,
    schema: 'brand1',
    variant: 'hard'
  },

  PAUSED: {
    icon: faCirclePause,
    schema: 'notice',
    variant: 'ghost'
  },

  FINISHED: {
    icon: faTrophyStar,
    schema: 'positive',
    variant: 'hard'
  },

  PENDING: {
    icon: faPenLine,
    schema: 'neutral',
    variant: 'ghost'
  },

  CANCELLED: {
    icon: faCircleXmark,
    schema: 'negative',
    variant: 'hard'
  },

  SEARCHING_CANDIDATES: {
    icon: faMagnifyingGlass,
    schema: 'brand2',
    variant: 'hard'
  }
};
