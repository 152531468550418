import { Badge, Tooltip } from '@asgard/ui-library';
import { useI18n } from '@hooks/useI18n';
import { STATUS_DICT } from './OfferStatus.constants';
import { OfferStatusProps } from './OfferStatus.types';

export const OfferStatus: React.FC<OfferStatusProps> = ({ status }) => {
  const { t } = useI18n('common');

  return (
    <Tooltip content={t(`common.offer-status-desc.${status}`)}>
      <Badge
        icon={STATUS_DICT[status].icon}
        label={t(`common.offer-status.${status}`)}
        schema={STATUS_DICT[status].schema}
        variant={STATUS_DICT[status].variant}
        size="s"
      />
    </Tooltip>
  );
};
