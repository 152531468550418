import { Header, Stack } from '@asgard/ui-library';
import * as S from './HeaderWithActions.styled';
import { HeaderWithActionsProps } from './HeaderWithActions.types';

export const HeaderWithActions: React.FC<HeaderWithActionsProps> = ({
  title,
  actions,
  desc,
  subtitle,
  pretitle,
  prepend,
  titleSize = '3xl'
}) => {
  return (
    <S.Root>
      <S.Header>
        <Header as="h2" size={titleSize}>
          <Stack gap="m" alignment="center">
            {prepend && <S.Prepend>{prepend}</S.Prepend>}

            <Stack direction="column" gap="xs">
              {pretitle && <S.Pretitle>{pretitle}</S.Pretitle>}
              <span>{title}</span>
              {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
            </Stack>
          </Stack>
        </Header>

        {desc && <S.Desc>{desc}</S.Desc>}
      </S.Header>

      {actions && <S.Actions>{actions}</S.Actions>}
    </S.Root>
  );
};
