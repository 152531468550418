import { z } from 'zod';

export const MediaSchema = z.object({
  id: z.number(),
  filename: z.string(),
  url: z.string(),
  uploadPath: z.string(),
  mediaRole: z.string()
});

export type Media = z.infer<typeof MediaSchema>;
