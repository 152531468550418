import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
`;

export const Header = styled.div`
  flex: 1 1 auto;
`;

export const Subtitle = styled.div`
  font-size: 1rem;
  color: #999;
  font-weight: normal;
`;

export const Desc = styled.div`
  font-size: 1rem;
  font-weight: normal;
`;

export const Pretitle = styled.span`
  font-size: 1rem;
  font-weight: normal;
  color: #999;
`;

export const Prepend = styled.div`
  font-size: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1 0 auto;
  justify-content: flex-end;
`;
