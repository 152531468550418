import { HtmlTrans } from '@components/shared/HtmlTrans';
import { SLIDER_SETTINGS } from '@components/UserGuiding/UserGuiding.constants';
import Slider from 'react-slick';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferCandidatesPageContent: React.FC<{
  isAgency: boolean;
}> = ({ isAgency }) => {
  return (
    <Slider {...SLIDER_SETTINGS}>
      <S.Slide>
        <img src="/images/user-guiding-offer-candidates-page-01.png" />
        <HtmlTrans
          ns="common"
          i18nKey={`common.user-guiding-modal.offer_page_candidates_tab.slide1_${
            isAgency ? 'AGENCY' : 'TALENT_ENGINE'
          }.content`}
        />
      </S.Slide>

      <S.Slide>
        <img src="/images/user-guiding-offer-candidates-page-02.png" />
        <HtmlTrans
          ns="common"
          i18nKey={`common.user-guiding-modal.offer_page_candidates_tab.slide2_${
            isAgency ? 'AGENCY' : 'TALENT_ENGINE'
          }.content`}
        />
      </S.Slide>
    </Slider>
  );
};
