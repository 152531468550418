import { HtmlTrans } from '@components/shared/HtmlTrans';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferOfferedPageContent: React.FC<{
  isDraft: boolean;
}> = ({ isDraft }) => {
  return (
    <>
      {isDraft ? (
        <S.Slide>
          <img src="/images/user-guiding-offer-offered-page-01.png" />
          <HtmlTrans
            ns="common"
            i18nKey={`common.user-guiding-modal.offer-page-offered-tab.slide1_DRAFT.content`}
          />
        </S.Slide>
      ) : (
        <S.Slide>
          <img src="/images/user-guiding-offer-offered-page-02.png" />
          <HtmlTrans
            ns="common"
            i18nKey={`common.user-guiding-modal.offer-page-offered-tab.slide1_ACTIVE.content`}
          />
        </S.Slide>
      )}
    </>
  );
};
