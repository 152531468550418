import { OfferStatus } from '@components/OfferStatus';
import { HtmlTrans } from '@components/shared/HtmlTrans';
import { SLIDER_SETTINGS } from '@components/UserGuiding/UserGuiding.constants';
import { useI18n } from '@hooks/useI18n';
import Slider from 'react-slick';
import * as S from '../../UserGuiding.styled';

export const UserGuidingOfferListPageContent: React.FC = () => {
  const { t } = useI18n('common');

  return (
    <Slider {...SLIDER_SETTINGS}>
      <S.Slide>
        <img src="/images/user-guiding-offer-list-page-01.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer_list_page.slide1.content"
        />
      </S.Slide>
      <S.Slide>
        <img src="/images/user-guiding-offer-list-page-02.png" />
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer_list_page.slide2.content"
        />
      </S.Slide>
      <S.Slide>
        <HtmlTrans
          ns="common"
          i18nKey="common.user-guiding-modal.offer_list_page.slide3.content"
        />

        <S.HelpTable>
          <tr>
            <td>
              <OfferStatus status="PENDING" />
            </td>
            <td>{t('common:common.offer-status-desc.PENDING')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="SEARCHING_CANDIDATES" />
            </td>
            <td>{t('common:common.offer-status-desc.SEARCHING_CANDIDATES')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="ACTIVE" />
            </td>
            <td>{t('common:common.offer-status-desc.ACTIVE')}</td>
          </tr>

          <tr>
            <td>
              <OfferStatus status="PAUSED" />
            </td>
            <td>{t('common:common.offer-status-desc.PAUSED')}</td>
          </tr>
          <tr>
            <td>
              <OfferStatus status="FINISHED" />
            </td>
            <td>{t('common:common.offer-status-desc.FINISHED')}</td>
          </tr>
          <tr>
            <td>
              <OfferStatus status="CANCELLED" />
            </td>
            <td>{t('common:common.offer-status-desc.CANCELLED')}</td>
          </tr>
        </S.HelpTable>
      </S.Slide>
    </Slider>
  );
};
